<template>
  <div id="sec-three-right">
    <div class="question-block">
      <div class="question-text">
        Закажите бесплатную консультацию и для
        <br />Вас установка и настройка системы будет <br />БЕСПЛАТНОЙ
      </div>
      <input
        type="text"
        class="question-input"
        placeholder="Введите Ваше имя"
      />
      <input
        type="text"
        class="question-input"
        style="margin-top: 17px;"
        placeholder="Введите Ваш номер телефона"
      />
      <div class="buttons-grid buttons-grid-two">
        <div class="button button-right" @click="openSubmitPopup()">
          ЗАКАЗ ЗВОНКА
        </div>
      </div>
    </div>

    <div class="question-block">
      <div class="question-text">Получить образец договора на email</div>
      <input
        type="text"
        class="question-input"
        placeholder="Введите Ваш email"
      />
      <div class="buttons-grid buttons-grid-two">
        <div class="button button-right" @click="openSubmitPopup()">
          ОТРАВИТЬ
        </div>
      </div>
    </div>

    <div class="question-block">
      <div class="question-text">
        Получить коммерческое предложение на email
      </div>
      <input
        type="text"
        class="question-input"
        placeholder="Введите Ваш email"
      />
      <div class="buttons-grid buttons-grid-two">
        <div class="button button-right" @click="openSubmitPopup()">
          ОТРАВИТЬ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sec-three-right",
  methods: {
    openSubmitPopup() {
      this.$emit("openSubmitPopup");
    }
  }
};
</script>

<style lang="scss">
#sec-three-right {
  .question-block {
    margin-bottom: 17px;
    .question-text {
      margin-bottom: 13px;
      width: 98%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 18px;
      line-height: 22px;

      .info {
        @include info;
      }
    }

    .buttons-grid {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 15px;
      margin-top: 9px;

      .button {
        @include button-main;
        width: 180px;
        margin-left: auto;
        margin-right: auto;
        background: linear-gradient(
          90deg,
          rgba(33, 71, 211, 0.5) 0%,
          rgba(91, 179, 149, 0.5) 100%
        );
      }
    }

    .question-input {
      @include input-main;
      width: 98%;
    }
  }
}
</style>
